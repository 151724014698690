import { Injectable, afterNextRender, inject } from '@angular/core';

import { environment } from '../environments/environment';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthRepository } from './data/repositories/auth.repository';
import posthog, { PostHog } from 'posthog-js';
import * as Sentry from '@sentry/angular-ivy';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  // TODO - Build out dummy so things don't blow up if analytics fail
  #client!: PostHog;
  #auth = inject(AuthRepository);
  #router = inject(Router);
  constructor() {
    afterNextRender(() => {
      this.#client = posthog.init(environment.posthog.key)!;
      this.#init();
    });
  }

  async #init() {
    this.#auth.user$.subscribe((u) => {
      if (!u) {
        // No user, clear things out
        this.logout();
      } else {
        this.identify(u.id, u);
      }
    });
    // Call on first init, to get page we landed on
    await this.page();
    this.#router.events
      .pipe(
        untilDestroyed(this),
        filter((e) => e instanceof NavigationEnd)
      )
      .subscribe((_e) => {
        this.page();
      });
  }

  async identify(userId: string, options?: any) {
    this.#tc(async () => {
      await this.#client.identify(userId, options);
    });
  }

  async page(slug?: string, options?: any) {
    await this.#tc(async () => {
      await this.#client.capture('$pageview');
    });
  }

  async track(slug: string, data?: any) {
    await this.#tc(async () => {
      await this.#client.capture(slug, data);
    });
    Sentry.addBreadcrumb({
      category: 'event',
      message: `Completed "${slug}"`,
      data,
    });
  }

  async logout() {
    await this.#tc(async () => {
      this.#client.reset();
    });
  }

  async #tc(fn: () => Promise<void>) {
    try {
      await fn();
    } catch (e) {
      console.error('[analytics] unable to run', fn);
      Sentry.captureException(e);
    }
  }
}
