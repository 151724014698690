import { Injectable, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

const BRAND_NAME = environment.brand.name;

interface MetaData {
  title?: string;
  url?: string;
  image?: string;
  description?: string;
}

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  #title = inject(Title);
  #meta = inject(Meta);
  constructor() {
    // Kick off with this
    this.#setDefaults();
    this.#setTitle();
  }

  /**
   * Should maybe clear old meta tags on nav??
   * Probably doesn't matter much since this is mostly for SSR
   *
   * @param data
   */
  set(data: string | MetaData) {
    if (typeof data === 'string') {
      // It's just a title
      this.#setTitle(data);
    } else {
      const { title, image, url, description } = data;
      if (title) {
        this.#setTitle(title);
      }

      if (image) {
        this.#meta.addTags([
          {
            property: 'og:image',
            content: image,
          },
          {
            property: 'twitter:image',
            content: image,
          },
        ]);
      }

      if (url) {
        this.#meta.addTags([
          {
            property: 'og:url',
            content: url,
          },
          {
            property: 'twitter:url',
            content: url,
          },
        ]);
      }

      if (description) {
        this.#meta.addTags([
          {
            property: 'og:description',
            content: description,
          },
          {
            property: 'twitter:description',
            content: description,
          },
          {
            property: 'description',
            content: description,
          },
        ]);
      }
    }
  }

  #setTitle(title?: string) {
    const fullTitle = this.#formattedTitle(title);
    this.#title.setTitle(fullTitle);
    this.#meta.addTags([
      {
        property: 'twitter:title',
        content: fullTitle,
      },
      {
        property: 'og:title',
        content: fullTitle,
      },
    ]);
  }

  #setDefaults() {
    this.#meta.addTags([
      {
        property: 'og:type',
        content: 'website',
      },
      {
        property: 'twitter:card',
        content: 'summary_large_image',
      },
    ]);
  }

  #formattedTitle(title?: string) {
    if (!title || title.trim() === BRAND_NAME) return BRAND_NAME;
    return `${title} | ${BRAND_NAME}`;
  }
}
