import { Component, Input } from '@angular/core';
import { LottieComponent, AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [LottieComponent],
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss',
})
export class LoaderComponent {
  @Input()
  width: string | null = null;

  @Input()
  height: string | null = null;

  @Input()
  animation = 'ip-loader';

  options: AnimationOptions = {
    path: `/assets/animations/${this.animation}.json`,
  };
}
